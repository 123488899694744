// topbar menu styles start
.topBarImage {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin: 0px;
    margin-bottom: 10px
}

.topBarImageAlignment {
    text-align: center;
}

.themeColorText {
    color: #f17038;
}

.paginationcss {
    margin-right: 0;
}

.qrimage {
    margin-left: 600px;
    width: 10%;
    height: 10%;
    margin-top: 200px;
}

.topbarMenu {
    width: 200px;
    border-radius: 0;
    border: none;
    padding-bottom: 15px 0;
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.05);
    margin-top: 0;
}

.theme-white {
    border: 1px solid #ddd !important;
    background: white !important
}

.iconstyle {
    background: #d6d7da !important;
    border: 1px solid #d6d7da !important;
    height: 38px;
}

.mandatoryStyle {
    color: rgb(230, 17, 17) !important;
    font-weight: bold;
}

.modal-companySuccess {
    max-width: 500px !important;
}

// topbar menu styles end
.form input {
    background: white;
    margin-bottom: 0.5rem;
}

body .p-datatable .p-datatable-tbody>tr.p-highlight {
    background-color: #d9d6d0 !important;
}

body .p-datatable .p-datatable-tbody>tr.p-highlightHoliday {
    background-color: #fbff2b;
}

body .p-datatable .p-datatable-tbody>tr.p-highlightWeekend {
    background-color: #1efd00;
}

body .p-datatable .p-datatable-tbody>tr.p-highlightLessThan6Hrs {
    background-color: #ff0000;
}

body .p-datatable .p-sortable-column .p-sortable-column-icon {
    color: white;

    &:hover {
        color: white;
    }
}

.checkListdiv {
    // position: relative;
    display: flex;
    font-size: 18px;
    justify-content: space-between;
    margin-bottom: 10px;
}

// .drop {

//     position: absolute;
//     top: 7%;
//     left: 2%;
// }

// .categorytype {
//     position: absolute;
//     top: 8%;
//     left: 18%;
// }

.drop1 {

    position: absolute;
    top: 7%;
    left: 15%;
}

.checkListspan {
    display: flex;
    font-size: 15px;
    // justify-content: center;
    color: $color-accent;
    font-weight: 500;
    margin-bottom: 10px;
}

.categorytype {
    position: absolute;
    top: 8%;
    left: 18%;
}

.inventoryCheckListCountsFacility {
    color: $color-accent;
}

.alignItemsCenter {
    justify-content: center;
}

.otpErrorStyle {
    color: red;
}

.noOtpCodeStyle {
    color: darkgray
}

.resendColorStyle {
    color: #3475aa
}

.fullWidth {
    width: 100%
}

.errorPadding {
    padding-left: 36px;
}

.letterBold {
    font-weight: 600;
}


.error {
    position: fixed;
    left: 0;
    // top:0;
    // height: 1250px;
    z-index: 100;
    width: 100%;
}

body .p-fluid .p-autocomplete .p-autocomplete-dropdown.p-button {
    width: 2em;
    height: 38px !important;
}

body .p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container .p-autocomplete-input-token input {
    font-size: 12px;
}

body .p-button {
    background-color: $color-accent
}

//autocomplete multi-select
.p-autocomplete-input-token {
    height: 25px;
}

.p-autocomplete-token.p-highlight {
    margin-bottom: 2px;
}

// Dasboard 
.dashBoardCardWithScroll {
    height: calc(100vh - 280px);
    padding-bottom: 20px;
}

.table_column_width1 {
    max-width: 15%;
}

.table_column_width2 {
    width: 9.2%;
}

.table_column_width1,
.table_column_width2 {
    text-align: center;
}

//Scrollable table with Sticky Header
.table-fixed tbody {
    max-height: 250px;
    overflow-y: auto;
    width: 100%;
}

.table-fixed thead,
.table-fixed tbody,
.table-fixed tr,
.table-fixed td,
.table-fixed th {
    display: block;
}

.table-fixed tr:after {
    content: "";
    display: block;
    visibility: hidden;
    clear: both;
}

.table-fixed tbody td,
.table-fixed thead>tr>th {
    float: left;
}

.inventory-check-tbody {
    display: block;
    max-height: 550px;
    overflow-y: scroll;
}

.inventory-check-thead,
.inventory-check-tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
}

.table thead th {
    vertical-align: middle !important;
}

//kpiTable
.kpiTable {
    background-color: $color-accent;
    color: black;
}

.registerselect {
    border: 1px solid rgb(204, 204, 204) !important;
    border-radius: 4px;
}

.colorLegend {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 10px;
}

body .p-multiselect-panel .p-multiselect-items {
    color: black
}

.p-multiselect-item.p-highlight {
    background-color: $color-accent !important;
    color: black
}

.p-multiselect-label-container {
    height: 35.5px;
    padding-top: 0.35rem;
}

body .p-input-overlay-visible {
    z-index: 9999 !important;
}

.color-picker__popover {
    z-index: 9999;
}

.calendar .rbc-off-range-bg {
    background-color: #e5e5e5;
}

.textElipses {
    font-weight: 600;
    cursor: pointer;
    color: $color-accent;
}

.p-dropdown .p-dropdown-trigger {
    height: 100%;
}

body .p-multiselect-panel .p-multiselect-items {
    color: black
}

body .p-multiselect-item.p-highlight {
    background-color: $color-accent;
    color: black
}

.dashboardWarningColor {
    color: red;
    text-align: center
}

.dashboardSuccessIconColor {
    color: green;
    text-align: center;
    text-transform: capitalize
}

.dashboardLocationName {
    text-transform: capitalize;
}

.categoryAddIcon {
    color: white;
    padding: 3.5px;
    // margin-left: 5px;
    border-radius: 58px;
    font-size: 28px;

    cursor: pointer
}

.inventoryCheckRowTextColor {
    text-align: center;
    color: black
}

.inventoryCheckRow {
    text-align: center;
    color: whitesmoke !important;
    border: 1px solid white !important
}

.add_to_orders_btn {
    margin: auto;
    padding: 0px 7px;
}

.deleteModalBody {
    text-align: center;
}

.deleteModalText {
    margin-bottom: 10px;
    font-size: 15px;
}

.deleteModalWidth {
    max-width: 500px;
}

.detailsImgStyle {
    width: 100px;
    height: 100px;
    border-radius: 50%;
}

.storageTypeCount_hover:hover {
    color: $color-accent !important;
    font-weight: bold;
}

.alignRight {
    justify-content: flex-end;
    padding-top: 10px;
}

.payment_input {
    background-color: #fff;
    border-color: #9c9c9c;
    border-width: 1px;
    border-style: solid;
    border-radius: 3px;
    padding: 7px 15px;
    color: #5d6066;
    width: 100%;
    font-weight: 3000;
    font-size: 14px;
    outline: none;

    &:active &:focus {
        outline: none;
    }

    &::-webkit-input-placeholder {
        color: #9c9c9c;
        font-weight: 500;

    }

    &::-moz-placeholder {
        color: #9c9c9c;
        font-weight: 500;

    }

    &:-moz-placeholder {
        color: #9c9c9c;
        font-weight: 500;

    }

    &:-ms-input-placeholder {
        color: #9c9c9c;
        font-weight: 500;

    }
}

.payment_fields {
    background-color: #fff;
    border-color: #9c9c9c;
    border-width: 1px;
    border-style: solid;
    border-radius: 3px;
    padding: 7px 15px
}

@media screen and (max-width: 575.5px) {
    .table_data_Desktop {
        display: none;
    }

    .cart {
        left: 75%;
    }
}


@media screen and (min-width: 575.5px) {
    .listData_mobile {
        display: none;
    }

}

.p-column-title {
    color: #fff
}

.checkbox-btn .checkbox-btn__checkbox-custom {
    border: 1px solid;
}

.paddingRowDataCol {
    text-align: left;
    padding: 5px 5px;
}

.paddingOfRowData {
    padding: 5px 0;
    text-align: left;
}

.fontWeight {
    font-weight: bold;
    text-transform: capitalize;
}

.updatebtn {
    font-weight: 400;
}

.cartDisplay {
    margin-left: 0.6rem;
    display: flex;
    margin-right: 5px;
    margin-top: 0.1rem;
}

.cart_badge {
    height: fit-content;
    margin-bottom: auto;
}

.globalHeadingStyles {
    display: flex;
    justify-content: space-between;
}

.headerInfostyle {
    display: flex;
    justify-content: center;
    // width: 100%;
    margin-left: 1.5rem;
}

.TypeHeadingStyles {
    margin: 3rem;
}

.react-select__single-value .template_icon {
    display: none
}

.topbar__menu-wrap {
    padding: 0 0px !important;
    min-width: 10px !important;
}

.confirm_modal {
    position: fixed;
    top: 40%;
    left: 40%;
    transform: translate(-50%, -50%);
    width: 400px;
    background: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.userLog_Sidebar {
    height: 100vh;
    position: fixed;
    width: 60px;
    background-color: #3880ba;
}

.custom-link {
    text-decoration: underline;
    color: #007ad9;
    cursor: pointer;
}

.account__btns .ml-auto.btn.btn-primary.disabled {
    background: #999999;
    color: #fff;
}

.textCapitalize {
    text-transform: capitalize;
}

.word_align {
    width: 220px;
    max-height: 100px;
    overflow-y: auto;
    white-space: pre-wrap;
    word-break: break-all;
}

.ml-auto.btn.btn-primary.disabled {
    background: #999999;
    color: #fff;
}

.align_table {
    width: 100%;
    margin-top: 20px;
    max-height: 300px;
    overflow: auto;
}



.align_modal_header {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: white;
}