/* Table look changes start */

body .p-datatable .p-datatable-header,
body .p-datatable .p-datatable-footer {
    background-color: #ffffff;
    border:none;
    border-bottom: 0;
}

body .p-paginator {
    border: 1px solid #e5e5e5;
    background-color: #fff !important;
}

body .p-datatable .p-datatable-thead>tr>th {
    border: 1px solid #e5e5e5;
    background-color: #ffffff;
}
/* th.p-sortable-column.p-resizable-column{
    padding: 0px !important;
} */
body .p-datatable .p-datatable-tbody>tr>td {
    border: none;
}


/* Table look changes end */

body .pi {
    font-size: 1em;
}

.imgStyles {
    width: 30px;
    height:30px;
    margin-top: 0px;
    border-radius: 50%;
}
body .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    background-color: #3475aa;
    color: #ffffff;
}
body .p-datatable .p-datatable-tbody > tr.p-highlight {
    background-color: #64677778;
    color: #ffffff;
}
body .p-datatable .p-datatable-tbody > tr:nth-child(even).p-highlight {
    background-color: #64677778;
    color: #ffffff;
}